import Link from 'next/link'
import LayoutBasic from '../layouts/layoutBasic'
import { TOKEN, GET_USER_URL, UPLOAD_PICXY } from '../vars'
import { GET, POST, UPOST } from '../helpers'
import { withRouter } from 'next/router'
import { getLocalKeyValue } from '../storage'
import { SeoMetaBasic } from '../components/seoMeta'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faImage, faCamera } from "@fortawesome/free-solid-svg-icons";

class Login extends React.Component {

  render() {
    let main_contect = <>
      <div className="my-5 text-center">
        <h2 className="h4 mb-1">I want to Login as</h2>
        <p className="f-18">Select the type of account for which you want to Login</p>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-5 col-sm-6 border-right border-hidden-xs p-3">
          <Link href="/login-customer/">
            <div className="border rounded p-sm-3 py-2 px-1 pointer">
              <div className="d-flex">
                <div className="me-3 align-self-center border border-picxy p-3 rounded">
                  <img src="static/img/signup/client.svg" />
                </div>
                <div>
                  <p className="f-24 text-picxy m-0">I am a customer</p>
                  <p className="f-18 m-0">I want to buy content</p>
                </div>
                <div className="align-self-center ms-auto">
                  <FontAwesomeIcon icon={faArrowRight} className="text-dark" />
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-5 col-sm-6 p-3">
          <a href="https://upload.picxy.com/login" className="no-effect">
            <div className="border rounded p-sm-3 py-2 px-1 pointer">
              <div className="d-flex">
                <div className="me-3 align-self-center border border-phtr p-3 rounded">
                  <img className="" src="static/img/signup/camera.svg" />
                </div>
                <div>
                  <p className="f-24 text-phtr m-0">I am a photographer</p>
                  <p className="f-18 m-0">I want to sell content</p>
                </div>
                <div className="align-self-center ms-auto">
                  <FontAwesomeIcon icon={faArrowRight} className="text-dark" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
    return <LayoutBasic title="Picxy Login" {...this.props}>
      <SeoMetaBasic name="Picxy Login" description="Select the type of account for which you want to Login" {...this.props} />
      <div className="container mb-4 ">
        <div>
          <div className="card-body p-0 p-sm-5">
            {main_contect}
          </div>
        </div>
      </div>
    </LayoutBasic>
  }
}

export default withRouter(Login)
